//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      pagination: {
        current: 1,
        size: 10,
        total: 0,
        searchKey: "",
        userStatus: null,
        userType: null
      },
      users: [],
      changeUser: {
        id: null,
        userType: null
      },
      editVisible: false
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    this.getUsers();
  },
  mounted: function mounted() {},
  methods: {
    clearSearch: function clearSearch() {
      this.pagination = {
        current: 1,
        size: 10,
        total: 0,
        searchKey: "",
        userStatus: null,
        userType: null
      };
      this.getUsers();
    },
    getUsers: function getUsers() {
      var _this = this;

      this.$http.post(this.$constant.baseURL + "/admin/user/list", this.pagination, true).then(function (res) {
        if (!_this.$common.isEmpty(res.data)) {
          _this.users = res.data.records;
          _this.pagination.total = res.data.total;
        }
      }).catch(function (error) {
        _this.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    changeUserStatus: function changeUserStatus(user) {
      var _this2 = this;

      this.$http.get(this.$constant.baseURL + "/admin/user/changeUserStatus", {
        userId: user.id,
        flag: user.userStatus
      }, true).then(function (res) {
        _this2.$message({
          message: "修改成功！",
          type: "success"
        });
      }).catch(function (error) {
        _this2.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    changeUserAdmire: function changeUserAdmire(user) {
      var _this3 = this;

      if (!this.$common.isEmpty(user.admire)) {
        this.$confirm('确认保存？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success',
          center: true
        }).then(function () {
          _this3.$http.get(_this3.$constant.baseURL + "/admin/user/changeUserAdmire", {
            userId: user.id,
            admire: user.admire
          }, true).then(function (res) {
            _this3.$message({
              message: "修改成功！",
              type: "success"
            });
          }).catch(function (error) {
            _this3.$message({
              message: error.message,
              type: "error"
            });
          });
        }).catch(function () {
          _this3.$message({
            type: 'success',
            message: '已取消保存!'
          });
        });
      }
    },
    editUser: function editUser(user) {
      this.changeUser.id = user.id;
      this.changeUser.userType = user.userType;
      this.editVisible = true;
    },
    handlePageChange: function handlePageChange(val) {
      this.pagination.current = val;
      this.getUsers();
    },
    searchUser: function searchUser() {
      this.pagination.total = 0;
      this.pagination.current = 1;
      this.getUsers();
    },
    handleClose: function handleClose() {
      this.changeUser = {
        id: null,
        userType: null
      };
      this.editVisible = false;
    },
    saveEdit: function saveEdit() {
      var _this4 = this;

      this.$http.get(this.$constant.baseURL + "/admin/user/changeUserType", {
        userId: this.changeUser.id,
        userType: this.changeUser.userType
      }, true).then(function (res) {
        _this4.handleClose();

        _this4.getUsers();

        _this4.$message({
          message: "修改成功！",
          type: "success"
        });
      }).catch(function (error) {
        _this4.$message({
          message: error.message,
          type: "error"
        });
      });
    }
  }
};